import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAmH3-fZfi00b9xXDbjeDqyc10360763kc",
    authDomain: "contactter-9ee25.firebaseapp.com",
    projectId: "contactter-9ee25",
    storageBucket: "contactter-9ee25.appspot.com",
    messagingSenderId: "833946815149",
    appId: "1:833946815149:web:7e6d8facf5c9e7c212d424",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
